<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card class="bg-transparent border-r-5">
        <div slot="no-body" class="full-page-bg-color border-r-5">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/davivienda.png" alt="login" class="responsive p-6">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg border-trr-5 border-brr-5">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Iniciar sesión</h4>
                  <p>Bienvenido, por favor ingrese sus credenciales.</p>
                </div>
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="people-soft"
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="People Soft"
                  v-model="peopleSoft"
                  class="w-full no-icon-border"/>
                <span class="text-danger text-sm">{{ errors.first('peopleSoft') }}</span>

                <vs-input
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  type="password"
                  name="password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  label-placeholder="Contraseña"
                  v-model="password"
                  class="w-full mt-6 no-icon-border"/>
                <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                <div class="flex flex-wrap justify-between my-5">
                  <vs-row vs-type="flex" vs-justify="flex-end">
                    <vs-button class="mr-0" :disabled="!validateForm" @click="login">Ingresar</vs-button>
                  </vs-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import ability from "../../utils/ability";

  export default {
    data() {
      return {
        peopleSoft: '',
        password: '',
        checkbox_remember_me: false
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.peopleSoft !== '' && this.password !== '';
      },
    },
    methods: {
      async login() {
        await axios.post(
          process.env.VUE_APP_AUTH_URL, {}, {auth: {username: this.peopleSoft, password: this.password}}
        )
          .then(response => {
            if (this.$vs.loading.close) this.$vs.loading.close();
            let permissions = response.data.permissions;
            Object.assign(permissions, {"dashboard": {"retrieve": true}});
            localStorage.setItem('userInfo', JSON.stringify({userName: response.data.user_name}));
            localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
            let rawRules = [];
            for (let moduleName in permissions) {
              let actions = permissions[moduleName];
              for (let action in actions) {
                if (actions[action]) {
                  rawRules.push({subject: moduleName, actions: action});
                }
              }
            }
            ability.update(rawRules);
            this.$router.push({name: "dashboard-analytics"});
          })
          .catch(error => {
            if (this.$vs.loading.close) this.$vs.loading.close();
            this.$vs.notify({
              time: 2500,
              title: 'Error de autenticación',
              text: "Usuario o contraseña inválida. Intentelo nuevamente",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          });
      },

      notifyAlreadyLogedIn() {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        });
      }
    }
  }
</script>

<style lang="scss">
  #page-login {
    .bg-transparent {
      background-color: transparent;
    }

    .border-r-5 {
      border-radius: 5px;
    }

    .border-trr-5 {
      border-top-right-radius: 5px;
    }

    .border-brr-5 {
      border-bottom-right-radius: 5px;
    }

    .social-login {
      .bg-facebook {
        background-color: #1551b1;
      }

      .bg-twitter {
        background-color: #00aaff;
      }

      .bg-google {
        background-color: #4285F4;
      }

      .bg-github {
        background-color: #333;
      }
    }
  }
</style>
